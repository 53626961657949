import { postBaseSchema } from "@/renderer/schema";
import { z } from "zod";
import { BlogCard } from "../ui/blog-card";

const PopularBlogs: React.FC<{ posts: z.infer<typeof postBaseSchema>[] }> = ({
  posts,
}) => {
  return (
    <>
      <h2 className="text-3xl uppercase my-5 font-bold">Popular blogs</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 gap-y-12 md:px-0">
        {posts?.map((post) => (
          <BlogCard key={post.id} post={post} />
        ))}
      </div>
    </>
  );
};

export default PopularBlogs;
